import { Box, Divider, Link, SimpleGrid } from '@chakra-ui/react';
import { graphql, Link as GatsbyLink, useStaticQuery } from 'gatsby';
import React from 'react';
import { BigPost } from '../components/BigPost';
import { BlogItem } from '../components/BlogItem';
import { CardItem } from '../components/CardItem';
import { Content } from '../components/Content';
import Layout from '../components/Layout';
import { Section } from '../components/Section';
import { useTranslation } from '../contexts/LanguageContext';

type PostsData = {
  date: string;
  lang: string;
  slug: string;
  thumbnail: string;
  title: string;
};

type Post = {
  node: {
    id: string;
    excerpt: string;
    frontmatter: PostsData;
    timeToRead: number;
  };
};

export default function IndexPage() {
  const { t, locale } = useTranslation();
  const prefix = locale === 'en' ? 'en/' : '/';

  const data = useStaticQuery(graphql`
    query {
      allMarkdownRemark(
        sort: { fields: frontmatter___date, order: DESC }
        limit: 22
      ) {
        edges {
          node {
            id
            excerpt
            frontmatter {
              date
              slug
              title
              thumbnail
              lang
            }
            timeToRead
          }
        }
      }
    }
  `);

  const posts = data.allMarkdownRemark.edges;
  const filteredPosts = posts.filter((edge: Post) =>
    edge.node.frontmatter.lang.includes(locale)
  );

  const feature: Post = filteredPosts[0];
  const secondary: Post[] = filteredPosts.slice(1, 4);
  const other: Post[] = filteredPosts.slice(5, 11);

  return (
    <Layout title="Home" isHome>
      <BigPost
        bg={feature.node.frontmatter.thumbnail}
        link={`${prefix}post${feature.node.frontmatter.slug}`}
        title={feature.node.frontmatter.title}
      />

      <Content>
        <SimpleGrid columns={[1, 3]} gap={6}>
          {secondary.map(({ node }) => {
            const { title, thumbnail, slug } = node.frontmatter;

            return (
              <CardItem
                img={thumbnail}
                key={node.id}
                link={`${prefix}post${slug}`}
                title={title}
              />
            );
          })}
        </SimpleGrid>

        <Divider variant="dashed" maxW="60vw" w="100%" m="4rem auto" />

        <Section title={t('allarticles')} columns={2}>
          {other.map(({ node }) => {
            const { title, slug, date, lang } = node.frontmatter;

            return (
              <BlogItem
                date={date}
                isHome
                key={node.id}
                lang={lang}
                slug={`${prefix}post${slug}`}
                text={node.excerpt}
                timeToRead={node.timeToRead}
                title={title}
              />
            );
          })}
        </Section>

        <Divider variant="dashed" maxW="60vw" w="100%" m="4rem auto" />

        <Box textAlign="center" mb={['10rem', 0]}>
          <Link
            _hover={{ color: 'gold' }}
            as={GatsbyLink}
            fontSize="3xl"
            fontWeight="black"
            textAlign="center"
            to="/articles/"
          >
            {t('allarticles')}
          </Link>
        </Box>
      </Content>
    </Layout>
  );
}
