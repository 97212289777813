import {
  AspectRatio,
  Flex,
  Heading,
  Image,
  LinkBox,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';
import { Link } from 'gatsby';
import React from 'react';

type Props = {
  desc?: string;
  img?: string;
  link: string;
  tag?: string;
  title: string;
};

export function CardItem({ title, img, desc, link }: Props) {
  const color = useColorModeValue('gray.500', 'gray.300');

  return (
    <LinkBox
      _hover={{ filter: 'brightness(1.1)' }}
      as="article"
      pos="relative"
      py="6"
      transition="all .2s"
    >
      <Link to={link}>
        <AspectRatio ratio={1}>
          <Image
            alt="pic"
            borderRadius="xl"
            boxShadow="2xl"
            loading="lazy"
            src={img}
          />
        </AspectRatio>
      </Link>

      <Flex py="3" w="100%" justifyContent="space-between" align="flex-start">
        <Heading
          as="h3"
          fontSize="lg"
          fontWeight="900"
          my={0}
          textAlign={['center', 'left']}
        >
          <Link to={link}>{title}</Link>
        </Heading>
      </Flex>
      {desc && (
        <Text w="100%" color={color} fontSize="1rem" my={0}>
          {desc}
        </Text>
      )}
    </LinkBox>
  );
}
