import { Link } from 'gatsby';
import React from 'react';
import { Hero } from './Hero';

type Props = {
  bg: string;
  link: string;
  title: string;
};

export function BigPost({ title, bg, link }: Props) {
  return (
    <Link to={link}>
      <Hero title={title} bg={bg} />
    </Link>
  );
}
